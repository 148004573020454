export const KEY_CHOOSE_A_CHARACTER = 'app_choose_character';
export const KEY_CONFIRM_CHARACTER = 'app_confirm_character';
export const KEY_YES = 'app_yes';
export const KEY_NO = 'app_no';
export const KEY_UP_FOLDER = 'app_up_folder';
export const KEY_CHARACTER_ALREADY_CHOSEN = 'app_character_already_chosen';
export const KEY_ONLY_YOU_CAN_SEE_THIS = 'app_onlyyoucanseethis';
export const KEY_CANNOT_CHANGE_MIND = 'app_cannot_change_mind';
export const KEY_GOOD_TO_GO = 'app_good_to_go';
export const KEY_WAIT_FOR_GAME_START = 'app_wait_for_game_start';
export const KEY_YOUR_CHARACTER = 'app_your_character';
export const KEY_GAME_ENDED = 'app_game_ended';
export const KEY_TANKS_FOR_PLAYING = 'app_thanks';
export const KEY_GAME_NAME = 'game';
export const KEY_NAMETAG = 'app_nametag';
export const KEY_LEAVE_GAME = 'app_leave';

