const _isTouchDevice = () => {
    const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];
    const mq = query => window.matchMedia(query).matches;

    if (
        'ontouchstart' in window ||
        // eslint-disable-next-line no-undef
        (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
        return true;
    }
    return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
};

let touch = 'initial';

/**
 * @returns {boolean}
 */
export const isTouchDevice = () => {
    if (touch === 'initial') {
        touch = _isTouchDevice();
        console.log('Is this touch device: ', touch);
    }
    return touch;
}
