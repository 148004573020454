import React, {useEffect} from "react";
import {batch, useDispatch, useSelector, useStore} from "react-redux";
import {
    convertWireGameEventToAction,
    currentGameState,
    GameState,
    lastReceivedEventIdSelector,
    myGameInstanceSelector
} from "./redux";
import {Api} from "./api";

export type ShortPollingSyncProps = {
    interval: number;
};

export function ShortPollingSync({interval}: ShortPollingSyncProps) {
    const gameInstance = useSelector(myGameInstanceSelector);
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
        if (gameInstance == null) {
            return;
        }

        console.debug(`Starting sync with interval ${interval} for game ${gameInstance.id}...`);
        const retrieveEvents = (async () => {
            let lastEventId = lastReceivedEventIdSelector(store.getState());
            let gameState = currentGameState(store.getState());

            let events = [];
            try {
                if (gameState !== GameState.FINISHED_GAME) {
                    events = await Api.getGameEvents(gameInstance.id, lastEventId);
                }
            } catch (e) {
                console.error(`Error while retrieving game events! ${e}`);
            }

            if (events && events.length > 0) {
                let maxEventId = events[events.length - 1].id;
                let actions = events.map(event => convertWireGameEventToAction(event));

                // sanity check
                if (maxEventId <= lastEventId) {
                    throw Error(`Application desync: maxEventId (${maxEventId}) <= lastEventId ${lastEventId}!`);
                }

                // dispatch all action in one rerender
                batch(() => actions.forEach(it => dispatch(it)));

                console.log(`Received ${events.length} events, dispatched ${actions.length} actions, new last event id: ${maxEventId}`);
            }
        });

        retrieveEvents();
        const intervalId = setInterval(() => retrieveEvents(), interval);

        return () => {
            console.debug("Stopping sync...")
            clearInterval(intervalId);
        };
    }, [dispatch, store, gameInstance, interval]);

    return <></>;
}
