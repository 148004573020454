import {KEY_UP_FOLDER} from "./translations";
import {createSelector} from "reselect";


/// data models & types ///
export type CharacterId = string;
export type DocumentId = string;
export type RevisionId = string;
export type ScreenId = string;
export type FolderId = string | null;
export type WireGameEvent = any;

export interface GameInstance {
    id: number;
    code: number;
    game_type: string;
    branch_id: number;
    created_at: string;
    finished_at: string;
}

export interface Character {
    id: CharacterId;
    name: string;
    profession: string;
    motto: string;
    boots_size: string;
    icon: string;
}

export interface Variant {
    id: RevisionId;
    name: string;
    affection: CharacterId[];
    file: string;
}

export interface Document {
    id: DocumentId;
    variants: Variant[];
    folder: FolderId;
    scope: string;
    placement: string;
    auto_publish?: any;
    hidden: boolean;
    characters: CharacterId[];
}

export interface Folder {
    id: FolderId;
    name: string;
}

export interface GameData {
    id: string;
    parent_id: string;
    name: string;
    characters: Character[];
    documents: Document[];
    folders: Folder[];
    translations: { [key: string]: string };
}

export interface Message {
    from: string;
    to: string;
    text: string;
}

export const DRAWER_ITEM_TYPE_FILE = 'file';
export const DRAWER_ITEM_TYPE_FOLDER = 'folder';

export interface DrawerItemFile {
    type: typeof DRAWER_ITEM_TYPE_FILE;
    document: Document | null;
    title: string;
}

export interface DrawerItemFolder {
    type: typeof DRAWER_ITEM_TYPE_FOLDER;
    folder: Folder | null;
    title: string;
}

export type DrawerItem = DrawerItemFile | DrawerItemFolder;

export enum GameState {
    NOT_IN_GAME = 0,
    SELECTING_CHARACTER = 1,
    IN_GAME = 2,
    FINISHED_GAME = 3,
}

/// store ///
export interface ApplicationState {
    gameData: GameData | null,
    gameInstance: GameInstance | null;
    claimedCharacters: CharacterId[];
    publishedDocuments: DocumentId[];
    highlightedDocuments: DocumentId[];
    documentRevisions: { [key: string]: string };
    chatMessages: Message[];
    selectedCharacter: CharacterId | null;
    isGameStarted: boolean;
    isGameFinished: boolean;
    currentScreen: ScreenId | null;
    currentFolder: FolderId;
    currentDocument: DocumentId | null;
    lastReceivedEventId: number;
    currentAppTitle: string | null;
}

export const INITIAL_STATE: ApplicationState = {
    claimedCharacters: [],
    chatMessages: [],
    publishedDocuments: [],
    documentRevisions: {},
    highlightedDocuments: [],
    lastReceivedEventId: 0,
    currentDocument: null,
    currentFolder: null,
    currentScreen: null,
    gameData: null,
    gameInstance: null,
    selectedCharacter: null,
    isGameStarted: false,
    isGameFinished: false,
    currentAppTitle: null,
};

/// actions ///
export interface NetworkGameAction {
    id: number;
    gameId: number;
    createdAt: Date;
}

const INITIALIZE_ACTION = 'init';
const SELECT_CHARACTER_ACTION = 'select_character';
const VIEW_DOCUMENT_ACTION = 'view';
const SELECT_FOLDER_ACTION = 'folder';
const SET_APP_TITLE = 'app_title';

const START_GAME_ACTION = 'start';
const PUBLISH_ACTION = 'publish';
const CHARACTER_CLAIMED_ACTION = 'character';
const FINISH_GAME_ACTION = 'finish';
const MESSAGE_ACTION = 'msg';
const SHOW_SCREEN_ACTION = 'screen';
const DOCUMENT_REVISION_ACTION = 'revision';

export interface StartGameAction extends NetworkGameAction {
    type: typeof START_GAME_ACTION;
}

export interface PublishDocumentAction extends NetworkGameAction {
    type: typeof PUBLISH_ACTION;
    documentId: DocumentId;
}

export interface CharacterClaimedAction extends NetworkGameAction {
    type: typeof CHARACTER_CLAIMED_ACTION;
    characterId: CharacterId;
}

export interface FinishGameAction extends NetworkGameAction {
    type: typeof FINISH_GAME_ACTION;
}

export interface MessageAction extends NetworkGameAction {
    type: typeof MESSAGE_ACTION;
    message: Message;
}

export interface ShowScreenAction extends NetworkGameAction {
    type: typeof SHOW_SCREEN_ACTION;
    screenId: ScreenId;
}

export interface DocumentRevisionAction extends NetworkGameAction {
    type: typeof DOCUMENT_REVISION_ACTION;
    documentId: DocumentId;
    newRevisionId: RevisionId;
}

export interface InitializeAction {
    type: typeof INITIALIZE_ACTION;
    gameInstance: GameInstance;
    gameData: GameData;
}

export interface SelectCharacterAction {
    type: typeof SELECT_CHARACTER_ACTION;
    characterId: CharacterId,
}

export interface ViewDocumentAction {
    type: typeof VIEW_DOCUMENT_ACTION;
    documentId: DocumentId,
}

export interface SelectFolderAction {
    type: typeof SELECT_FOLDER_ACTION;
    folderId: FolderId,
}

export interface SetAppTitle {
    type: typeof SET_APP_TITLE;
    title: string | null,
}

export type Action =
    StartGameAction
    | PublishDocumentAction
    | CharacterClaimedAction
    | FinishGameAction
    | MessageAction
    | ShowScreenAction
    | DocumentRevisionAction
    | InitializeAction
    | SelectCharacterAction
    | ViewDocumentAction
    | SelectFolderAction
    | SetAppTitle;


/// reducers ///
export function mysteryDinnerLogic(state: ApplicationState = INITIAL_STATE, action: Action): ApplicationState {
    switch (action.type) {
        case START_GAME_ACTION:
            return {...state, lastReceivedEventId: action.id, isGameStarted: true};
        case PUBLISH_ACTION:
            return {
                ...state,
                lastReceivedEventId: action.id,
                publishedDocuments: [...state.publishedDocuments, action.documentId],
                highlightedDocuments: [...state.highlightedDocuments, action.documentId]
            };
        case CHARACTER_CLAIMED_ACTION:
            return {
                ...state,
                lastReceivedEventId: action.id,
                claimedCharacters: [...state.claimedCharacters, action.characterId]
            };
        case FINISH_GAME_ACTION:
            return {...state, lastReceivedEventId: action.id, isGameFinished: true};
        case MESSAGE_ACTION:
            return {...state, lastReceivedEventId: action.id, chatMessages: [...state.chatMessages, action.message]};
        case SHOW_SCREEN_ACTION:
            return {...state, lastReceivedEventId: action.id, currentScreen: action.screenId};
        case DOCUMENT_REVISION_ACTION:
            return {
                ...state,
                lastReceivedEventId: action.id,
                documentRevisions: {
                    ...state.documentRevisions,
                    [action.documentId]: action.newRevisionId
                },
            };
        case INITIALIZE_ACTION:
            return {
                ...state,
                gameData: action.gameData,
                gameInstance: action.gameInstance,
                lastReceivedEventId: 0,
            };
        case SELECT_CHARACTER_ACTION:
            return {
                ...state,
                selectedCharacter: action.characterId,
                currentDocument: state.gameData?.documents.find(it => it.variants?.[0].file.includes('menovka') && it.variants?.[0].file.includes(action.characterId))?.id ?? null
            };
        case VIEW_DOCUMENT_ACTION:
            return {
                ...state,
                currentDocument: action.documentId,
                highlightedDocuments: state.highlightedDocuments.filter(it => it !== action.documentId)
            };
        case SELECT_FOLDER_ACTION:
            return {...state, currentFolder: action.folderId};
        case SET_APP_TITLE:
            return {...state, currentAppTitle: action.title};
        default:
            return state;
    }
}

/// action creators ///
export function initializeAction(gameInstance: GameInstance, gameData: GameData): InitializeAction {
    return {type: INITIALIZE_ACTION, gameData, gameInstance};
}

export function selectCharacterAction(characterId: CharacterId): SelectCharacterAction {
    return {type: SELECT_CHARACTER_ACTION, characterId};
}

export function viewDocumentAction(documentId: DocumentId): ViewDocumentAction {
    return {type: VIEW_DOCUMENT_ACTION, documentId};
}

export function selectFolderAction(folderId: FolderId): SelectFolderAction {
    return {type: SELECT_FOLDER_ACTION, folderId};
}

export function setAppTitle(title: string | null): SetAppTitle {
    return {type: SET_APP_TITLE, title};
}

/**
 * Converts on-wire game event object to valid redux action.
 * @param wireGameEvent
 */
export function convertWireGameEventToAction(wireGameEvent: WireGameEvent): Action {
    if (!wireGameEvent.type) {
        throw Error("Passed object has no type attribute.")
    }

    const attr = (attr: string, obj = wireGameEvent) => {
        if (!obj[attr]) {
            throw Error(`Passed object does not have required attribute ${attr}.`);
        }
        return obj[attr];
    };

    switch (wireGameEvent.type) {
        case 'start':
            return {
                type: START_GAME_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
            };
        case 'publish':
            return {
                type: PUBLISH_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
                documentId: attr('data'),
            };
        case 'character':
            return {
                type: CHARACTER_CLAIMED_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
                characterId: attr('data'),
            };
        case 'finish':
            return {
                type: FINISH_GAME_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id')
            };
        case 'msg':
            const msg = attr(`data`);

            return {
                type: MESSAGE_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
                message: {
                    from: attr(`from`, msg),
                    to: attr(`to`, msg),
                    text: attr(`text`, msg),
                }
            };
        case 'screen':
            return {
                type: SHOW_SCREEN_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
                screenId: attr('data')
            };
        case 'revision':
            const revision = attr('data');

            return {
                type: DOCUMENT_REVISION_ACTION,
                createdAt: attr('created_at'),
                gameId: attr('game_id'),
                id: attr('id'),
                documentId: revision.split('|')[0].trim(),
                newRevisionId: revision.split('|')[1].trim(),
            };
        default:
            throw Error(`Cannot convert unknown type ${wireGameEvent.type}.`)
    }
}

/// selectors ///
export const appTitleSelector = (state: ApplicationState) => state.currentAppTitle;
export const lastReceivedEventIdSelector = (state: ApplicationState) => state.lastReceivedEventId;
export const gameStartedSelector = (state: ApplicationState) => state.isGameStarted;
export const currentGameDataSelector = (state: ApplicationState) => state.gameData;
export const myCharacterIdSelector = (state: ApplicationState) => state.selectedCharacter;
export const myCharacterSelector = createSelector(
    [currentGameDataSelector, myCharacterIdSelector],
    (gameData, myCharacter) => gameData?.characters?.find(it => it.id === myCharacter)
);

export const myGameInstanceSelector = (state: ApplicationState) => state.gameInstance;
export const currentDocumentIdSelector = (state: ApplicationState) => state.currentDocument;
export const currentDocumentSelector = createSelector(
    [currentGameDataSelector, currentDocumentIdSelector],
    (gameData, documentId) => gameData?.documents?.find(it => it.id === documentId)
);
export const translationSelector = (translationKey: string) => (state: ApplicationState) => state.gameData?.translations[translationKey];

export const claimedCharactersSelector = (state: ApplicationState) => state.claimedCharacters;

/**
 * Returns characters with their availability status.
 * @param state app state
 */
export const availableCharacters: (state: ApplicationState) => [Character, boolean][] = createSelector(
    [currentGameDataSelector, claimedCharactersSelector],
    (gameData, claimedCharacters) => {
        if (gameData == null) {
            return [];
        }

        return gameData
            .characters
            .filter(character => character.id.toLowerCase() !== 'gm')
            .sort(sortBy('name'))
            .map(character => [character, !claimedCharacters.includes(character.id)]);
    }
);

export const currentFolderSelector = (state: ApplicationState) => state.currentFolder;
export const currentDocumentRevisionsSelector = (state: ApplicationState) => state.documentRevisions;
export const publishedDocuments = (state: ApplicationState) => state.publishedDocuments;

export const currentGameState = (state: ApplicationState) => {
    if (state.gameInstance === null) {
        return GameState.NOT_IN_GAME;
    } else if (state.isGameFinished || state.gameInstance.finished_at != null) {
        return GameState.FINISHED_GAME;
    } else if (state.selectedCharacter == null) {
        return GameState.SELECTING_CHARACTER;
    } else {
        return GameState.IN_GAME;
    }
}

export const currentDocumentRevisionSelector = createSelector(
    [currentGameDataSelector, currentDocumentSelector, currentDocumentRevisionsSelector],
    (gameData, currentDocument, revisions) => {
        if (gameData == null) return [null, null];
        if (currentDocument == null) return [null, null];

        const currentVariantId = revisions[currentDocument.id];
        const variant = currentDocument.variants.find(it => it.id === currentVariantId) ?? currentDocument.variants[0];

        return [currentDocument, variant];
    }
);


export const documentsStaticallyAssignedToMe = createSelector(
    [currentGameDataSelector, myCharacterIdSelector],
    (gameData, myCharacterId) => gameData?.documents.filter(it => {
        return it.scope === 'public' || it.scope === 'rules' || it.characters.includes(myCharacterId ?? '#invalid#')
    }) ?? [],
);

export const documentsCurrentlyVisibleToMe = createSelector(
    [documentsStaticallyAssignedToMe, publishedDocuments],
    (documents, published) => documents.filter(it => !it.hidden || published.includes(it.id)),
);

export const currentlyHighlightedDocumentIds = (state: ApplicationState) => state.highlightedDocuments;
export const currentlyHighlightedFolderIds: (state: ApplicationState) => FolderId[] = createSelector(
    [currentGameDataSelector, currentlyHighlightedDocumentIds],
    (gameData, highlighted) => highlighted.map(it => gameData?.documents.find(doc => doc.id === it)?.folder) as FolderId[],
);


export const browsableDrawerItems: (state: ApplicationState) => DrawerItem[] = createSelector(
    [currentGameDataSelector, documentsCurrentlyVisibleToMe, currentFolderSelector, currentDocumentRevisionsSelector],
    (gameData, visibleDocuments, currentFolder, documentRevisions) => {
        if (gameData == null) {
            return [];
        }

        const items = [];

        const addDocumentsInFolder = (folderId: FolderId) => visibleDocuments
            .filter(it => it.folder === folderId)
            .map(document => ({
                type: DRAWER_ITEM_TYPE_FILE,
                document: document,
                title: document.variants.find(variant => variant.id === documentRevisions[document.id])?.name ?? document.variants[0].name
            } as DrawerItemFile))
            .sort(sortBy('title'))
            .forEach(it => items.push(it));

        if (currentFolder != null) {
            // up item
            items.push({
                type: DRAWER_ITEM_TYPE_FOLDER,
                folder: null,
                title: gameData.translations[KEY_UP_FOLDER] ?? KEY_UP_FOLDER
            } as DrawerItemFolder);
        } else {
            // all folders
            gameData.folders
                .filter(folder => visibleDocuments.some(doc => doc.folder === folder.id && doc.placement !== 'menu'))
                .map(folder => ({
                    type: DRAWER_ITEM_TYPE_FOLDER,
                    folder: folder,
                    title: gameData.translations[`folder_${folder.name}`] ?? folder.name
                } as DrawerItemFolder))
                .sort(sortBy('title'))
                .forEach(it => items.push(it));
        }

        // all documents in this folder
        addDocumentsInFolder(currentFolder);

        return items;
    }
);

export const itemsInDrawerSelector = createSelector(
    [browsableDrawerItems],
    (items) => items.filter(it => {
        if (it.type === 'folder') return true;
        return it.document?.placement.toLowerCase() !== 'menu';
    }),
);

export const itemsInMenuSelector = createSelector(
    [documentsCurrentlyVisibleToMe],
    (visible) => visible.filter(it => it.placement.toLowerCase() === 'menu'),
);


/// helper fns ///
export function sortBy<T>(prop: keyof T): (a: T, b: T) => number {
    return (a, b) => (a[prop] > b[prop]) ? 1 : -1
}
