import {
    Avatar,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Snackbar,
    Typography,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {availableCharacters, myGameInstanceSelector, selectCharacterAction, setAppTitle} from "../redux";
import {Translated, useTranslation} from "../containers/Translated";
import {
    KEY_CANNOT_CHANGE_MIND,
    KEY_CHARACTER_ALREADY_CHOSEN,
    KEY_CHOOSE_A_CHARACTER,
    KEY_CONFIRM_CHARACTER,
    KEY_NO,
    KEY_YES
} from "../translations";
import {LoadingButton} from "../LoadingButton";
import {Alert} from "@material-ui/lab";
import {useAsset} from "../hooks";
import {Api} from "../api";

interface CharacterListItemProps {
    onSelect: () => void,
    name: string;
    icon: string;
    profession: string;
    motto: string;
    available: boolean;
}

function CharacterListItem({onSelect, name, icon, profession, motto, available}: CharacterListItemProps) {
    const [, avatarUrl] = useAsset(icon);

    return <ListItem button disabled={!available} onClick={() => onSelect()}>
        <ListItemAvatar>
            <Avatar alt={name} src={avatarUrl}/>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={<>
            <Typography component={"span"} style={{display: 'block'}} variant={"body2"}>{profession}</Typography>
            <Typography component={"span"} style={{display: 'block'}} variant={"body2"}>{motto}</Typography>
        </>}/>
    </ListItem>;
}

export function ChooseCharacter() {
    const dispatch = useDispatch();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState(false);
    const [selectedCharacter, setSelectedCharacter] = useState('');

    const gameInstance = useSelector(myGameInstanceSelector);

    const translation = useTranslation(KEY_CHOOSE_A_CHARACTER);
    useEffect(() => {
        dispatch(setAppTitle(translation));
    });

    const openConfirmDialogWith = (selected: string) => {
        setSelectedCharacter(selected);
        setConfirmDialogOpen(true);
    };

    const selectCharacter = async () => {
        setLoading(true);
        try {
            await Api.selectCharacter(gameInstance?.id ?? 0, selectedCharacter);
            dispatch(selectCharacterAction(selectedCharacter));
            setConfirmDialogOpen(false);
        } catch (e) {
            setConfirmDialogOpen(false);
            setLoading(false);
            setErrorMessage(true);
        }
    };

    const characters = useSelector(availableCharacters);

    return <Container>
        <List>
            {characters.map(it => <CharacterListItem key={it[0].id}
                                                     onSelect={() => openConfirmDialogWith(it[0].id)}
                                                     available={it[1]}
                                                     {...it[0]}/>)}
        </List>

        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <DialogTitle>
                <Translated translationKey={KEY_CONFIRM_CHARACTER}
                            replacement={characters.find(it => it[0].id === selectedCharacter)?.[0].name}/>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Translated translationKey={KEY_CANNOT_CHANGE_MIND}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                    <Translated translationKey={KEY_NO}/>
                </Button>
                <LoadingButton loading={isLoading} onClick={() => selectCharacter()} color="primary" autoFocus>
                    <Translated translationKey={KEY_YES}/>
                </LoadingButton>
            </DialogActions>
        </Dialog>

        <Snackbar open={isErrorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(false)}>
            <Alert variant="filled" severity="error">
                <Translated translationKey={KEY_CHARACTER_ALREADY_CHOSEN}/>
            </Alert>
        </Snackbar>

    </Container>
}
