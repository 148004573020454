import {useEffect, useState} from "react";
import {Api} from "./api";

/**
 * Returns object url for asset specified by relative path. This hook
 * internally downloads the required asset, creates an object url for
 * it by calling `URL.createObjectURL` and returns the created url.
 *
 * The create url will be automatically revoked by clean-up effect that
 * internally calls `URL.revokeObjectURL`.
 *
 * This function also returns a boolean indicating whether the object
 * url is ready (the asset is downloaded).
 *
 * @param relativePath relative path to the asset
 */
export function useAsset(relativePath: string): [boolean, string] {
    const [objectUrl, setObjectUrl] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        let url: string;

        async function startDownload() {
            try {
                const blob = await Api.getAsset<Blob>(relativePath);
                url = URL.createObjectURL(blob);
                setObjectUrl(url);
                setLoading(false);
            } catch (e) {
                setObjectUrl('');
            }
        }

        startDownload();

        return () => {
            if (url !== '') {
                URL.revokeObjectURL(url);
            }
        }
    }, [relativePath]);

    return [isLoading, objectUrl];
}
