import {Container, Typography} from "@material-ui/core";
import React from "react";
import {Translated} from "../containers/Translated";
import {KEY_GAME_ENDED, KEY_LEAVE_GAME, KEY_TANKS_FOR_PLAYING} from "../translations";
import {LoadingButton} from "../LoadingButton";

export function Finish() {
    const onLeaveGame = () => {
        localStorage.clear();
        window.location.href = window.location.href;
    };

    return <Container>
        <Typography variant={"h4"} style={{marginTop: '1em'}} gutterBottom><Translated translationKey={KEY_GAME_ENDED}/></Typography>
        <Typography><Translated translationKey={KEY_TANKS_FOR_PLAYING}/></Typography>
        <LoadingButton loading={false} onClick={onLeaveGame} variant="contained" color="primary"
                       style={{margin: '1em 0 0 auto'}}>
            <Translated translationKey={KEY_LEAVE_GAME}/>
        </LoadingButton>
    </Container>;
}
