import {Store} from 'redux';
import {ApplicationState} from "./redux";

export function playNotificationSound() {
    const audio = new Audio('./plucky.ogg');
    audio.play();
}

export function createNotificationObserver(store: Store<ApplicationState>) {
    let prevState = store.getState()

    return store.subscribe(() => {
        const nextState = store.getState()

        if (prevState.highlightedDocuments.length < nextState.highlightedDocuments.length) {
            playNotificationSound()
        }

        prevState = nextState
    })
}
