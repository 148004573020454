import {
    AppBar,
    Backdrop,
    Badge,
    Box,
    CircularProgress,
    Grid,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Toolbar,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Alert} from "@material-ui/lab";
import Pdf from '@mikecousins/react-pdf';
import styles from './Game.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {
    currentDocumentIdSelector,
    currentDocumentRevisionSelector,
    currentlyHighlightedDocumentIds,
    currentlyHighlightedFolderIds,
    Document,
    DRAWER_ITEM_TYPE_FILE,
    DrawerItem,
    gameStartedSelector,
    itemsInDrawerSelector,
    myCharacterIdSelector,
    selectFolderAction,
    setAppTitle,
    Variant,
    viewDocumentAction
} from "../redux";
import {Backspace, Description, Folder} from "@material-ui/icons";
import {useAsset} from "../hooks";
import {Translated, useTranslation} from "../containers/Translated";
import {KEY_GAME_NAME, KEY_GOOD_TO_GO, KEY_ONLY_YOU_CAN_SEE_THIS, KEY_WAIT_FOR_GAME_START} from "../translations";
import {isTouchDevice} from "../touch";

export type HighlightProps = {
    highlight: boolean;
};

export const Highlight: React.FunctionComponent<HighlightProps> = props => {
    if (props.highlight) {
        return <Badge color="secondary" badgeContent={'!'}>{props.children}</Badge>
    }
    return <>{props.children}</>;
};

interface DocumentDrawerItemProps {
    item: DrawerItem;
    highlighted: boolean;
}

function DocumentDrawerItem({item, highlighted}: DocumentDrawerItemProps) {
    const dispatch = useDispatch();
    const currentDocument = useSelector(currentDocumentIdSelector);

    const onClick = () => {
        if (item.type === DRAWER_ITEM_TYPE_FILE) {
            dispatch(viewDocumentAction(item?.document?.id ?? '#invalid#'));
        } else {
            dispatch(selectFolderAction(item?.folder?.id ?? null));
        }
    };

    let icon = item.type === 'folder' ? (item?.folder?.id == null ? <Backspace/> : <Folder/>) : <Description/>;

    return <ListItem selected={item.type === DRAWER_ITEM_TYPE_FILE && item?.document?.id === currentDocument}
                     className={item.type === DRAWER_ITEM_TYPE_FILE ? styles.itemFile : styles.itemFolder}
                     onClick={onClick} button>
        <Hidden lgUp>
            <ListItemText primary={<Highlight highlight={highlighted}>{item.title}</Highlight>}/>
        </Hidden>
        <Hidden mdDown>
            <ListItemIcon><Highlight highlight={highlighted}>{icon}</Highlight></ListItemIcon>
            <ListItemText primary={item.title}/>
        </Hidden>
    </ListItem>;
}

function DocumentsDrawer() {
    const items = useSelector(itemsInDrawerSelector);
    const highlightedDocuments = useSelector(currentlyHighlightedDocumentIds);
    const highlightedFolders = useSelector(currentlyHighlightedFolderIds);

    return <List>
        {items.map(it => it.type === 'folder' ?
            <DocumentDrawerItem key={it.type + it?.folder?.id} item={it}
                                highlighted={highlightedFolders.includes(it?.folder?.id ?? '#invalid#')}/> :
            <DocumentDrawerItem key={it.type + it?.document?.id} item={it}
                                highlighted={highlightedDocuments.includes(it?.document?.id ?? '#invalid#')}/>
        )}
    </List>
}

type ViewerProps = {
    document: Document;
    revision: Variant;
};


function Viewer({revision}: ViewerProps) {
    const [isLoading, objectUrl] = useAsset(revision.file);
    const [isRendering, setRendering] = useState(false);
    const gameStarted = useSelector(gameStartedSelector);
    const [scale, setScale] = useState(1.0);
    const [position, setPosition] = useState([0, 0]);
    const [originalScale, setOriginalScale] = useState([0, 0]);
    const maxScale = Math.max(Math.max(originalScale[0], originalScale[1]) * 0.75, 1.3);

    const zoomEnabled = !isTouchDevice();

    const onMouseMove = (e: React.MouseEvent<HTMLElement>) => {
        const ox = e.clientX - e.currentTarget.offsetLeft;
        const oy = e.clientY - e.currentTarget.offsetTop;
        setPosition([ox / e.currentTarget.clientWidth, oy / e.currentTarget.clientHeight]);
    };

    const onTriggerZoom = (e: React.MouseEvent<HTMLImageElement>) => {
        if (!zoomEnabled) return;

        if (scale === 1.0) {
            setScale(maxScale);
        } else {
            setScale(1.0);
        }
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        console.log(e.currentTarget.naturalWidth, e.currentTarget.width, e.currentTarget.naturalWidth / e.currentTarget.width);
        console.log(e.currentTarget.naturalHeight, e.currentTarget.height, e.currentTarget.naturalHeight / e.currentTarget.height);
        setOriginalScale([
            e.currentTarget.naturalWidth / e.currentTarget.width,
            e.currentTarget.naturalHeight / e.currentTarget.height
        ]);
    };


    const [x, y] = position;
    const zoomStyles = {
        transform: scale === 1.0 ? '' : `scale(${scale}) translateX(${(x - 0.5) * -25 * maxScale}%) translateY(${(y - 0.5) * -25 * maxScale}%)`,
    };

    useEffect(() => {
        if (revision.file.endsWith('.pdf')) {
            setRendering(true)
        }
    }, [revision, isLoading]);

    let inner;

    if (isLoading) {
        inner = <></>
    } else if (revision.file.endsWith('.pdf') && objectUrl !== '') {
        inner = <Pdf scale={1.35}
                     className={styles.pdfViewer + (isRendering ? ' ' + styles.opacity0 : '')}
                     file={objectUrl}
                     onDocumentLoadSuccess={() => setRendering(true)}
                     onPageRenderSuccess={() => setRendering(false)}
                     page={1}/>
    } else if (revision.file.endsWith('.jpg') || revision.file.endsWith('.jpeg') || revision.file.endsWith('.png')) {
        inner = <Box className={styles.imgViewer}>
            <img alt={revision.name}
                 className={scale === 1.0 ? styles.imgUnzoomed : styles.imgZoomed}
                 draggable="false"
                 style={zoomStyles}
                 onLoad={onImageLoad}
                 onClick={onTriggerZoom}
                 src={objectUrl}/>
        </Box>
    } else {
        inner = <Alert severity={"error"}>Cannot display unsupported document type!</Alert>
    }

    return <Box className={styles.viewer + (gameStarted ? '' : ' ' + styles.blur)} onMouseMove={onMouseMove}>
        <CircularProgress style={{position: "absolute", top: '50%'}}
                          className={isRendering || isLoading ? '' : styles.opacity0}/>
        {inner}
    </Box>;
}

export function Game() {
    const dispatch = useDispatch();
    const gameStarted = useSelector(gameStartedSelector);
    const [isToastOpen, setToastOpen] = useState(false);
    const [doc, rev] = useSelector(currentDocumentRevisionSelector);
    const myCharacterId = useSelector(myCharacterIdSelector);

    const translation = useTranslation(KEY_GAME_NAME);
    useEffect(() => {
        dispatch(setAppTitle(translation));
    });

    let viewer;
    if (doc !== null && rev !== null) {
        viewer = <Viewer document={doc as Document} revision={rev as Variant}/>;
    } else {
        viewer = <Alert style={{margin: '1em'}} severity={"info"}>No document is selected.</Alert>;
    }

    useEffect(() => {
        if (gameStarted) {
            setToastOpen(doc !== null && (doc as Document).characters.includes(myCharacterId ?? '#invalid#'));
        }
    }, [myCharacterId, doc, gameStarted]);

    return <>
        <Grid className={styles.outerGameContainer} container>
            <Grid className={styles.outerDrawerContainer} lg={2} md={2} sm={2} xs={12} item>
                <DocumentsDrawer/>
            </Grid>
            <Grid className={styles.outerGameContainer} sm xs={12} item>
                {viewer}

                <Snackbar open={isToastOpen} autoHideDuration={6000} onClose={() => setToastOpen(false)}>
                    <Alert variant="filled" severity="error">
                        <Translated translationKey={KEY_ONLY_YOU_CAN_SEE_THIS}/>
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
        <Backdrop open={!gameStarted} style={{zIndex: 99}}>
            <AppBar position={"fixed"} style={{bottom: 0, top: "auto"}}>
                <Toolbar>
                    <Box style={{flexGrow: 1}}>
                        <Typography variant="h6"><Translated translationKey={KEY_GOOD_TO_GO}/></Typography>
                        <Typography variant="subtitle2"><Translated
                            translationKey={KEY_WAIT_FOR_GAME_START}/></Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </Backdrop>
    </>
}
