import React from "react";
import {useSelector} from "react-redux";
import {translationSelector} from "../redux";

export type TranslatedProps = {
    translationKey: string,
    replacement?: string,
};

export function useTranslation(translationKey: string, replacement?: string): string {
    return (useSelector(translationSelector(translationKey)) ?? translationKey).replace('#', replacement ?? '#')
}


/**
 * Returns a translated string from current game definition. If the translation
 * is not found the translation key is returned instead of translated string.
 *
 * @param translationKey translation key
 * @param replacement optional string that will be used as replacement for `#` symbol
 * @constructor
 */
export function Translated({translationKey, replacement}: TranslatedProps) {
    return <>
        {useTranslation(translationKey, replacement)}
    </>
}
