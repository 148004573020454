import React from 'react';
import './App.css';
import {
    AppBar,
    Badge,
    Box,
    Button,
    createMuiTheme,
    Hidden,
    ThemeProvider,
    Toolbar,
    Typography
} from "@material-ui/core";
import {compose, createStore, Store} from 'redux';
import {createBrowserHistory} from 'history';
import {Router} from 'react-router-dom';
import {Welcome} from "./pages/Welcome";
import {ChooseCharacter} from "./pages/ChooseCharacter";
import {Game} from "./pages/Game";
import {
    appTitleSelector,
    currentDocumentRevisionsSelector,
    currentGameState,
    currentlyHighlightedDocumentIds,
    Document, gameStartedSelector,
    GameState,
    itemsInMenuSelector,
    myCharacterSelector,
    mysteryDinnerLogic,
    viewDocumentAction
} from "./redux";
import {Provider, useDispatch, useSelector} from "react-redux";
import {ShortPollingSync} from "./sync";
import {Finish} from "./pages/Finish";
import {persistReducer, persistStore} from 'redux-persist'
import {PersistGate} from "redux-persist/integration/react";
import storage from 'redux-persist/lib/storage';
import {Translated} from "./containers/Translated";
import {KEY_NAMETAG, KEY_YOUR_CHARACTER} from './translations';
import {createNotificationObserver} from './sound';


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
    }
}

const history = createBrowserHistory();
const store = createStore(persistReducer({
    key: '_data',
    storage
}, mysteryDinnerLogic), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const persistor = persistStore(store as unknown as Store);
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#d43b3b'
        },
        secondary: {
            main: '#ffc800'
        }
    }
});

/* register notification callback */
createNotificationObserver(store as unknown as Store);

function AppTitle() {
    const title = useSelector(appTitleSelector);
    return <>{title ?? 'Mystery Dinner'}</>;
}

function MyCharacter() {
    const character = useSelector(myCharacterSelector);
    if (character)
        return <Translated translationKey={KEY_YOUR_CHARACTER} replacement={character?.name}/>
    return (null);
}

function ScreenSwitch() {
    const gameState = useSelector(currentGameState);

    switch (gameState) {
        case GameState.NOT_IN_GAME:
            return <Welcome/>;
        case GameState.FINISHED_GAME:
            return <Finish/>;
        case GameState.SELECTING_CHARACTER:
            return <ChooseCharacter/>;
        case GameState.IN_GAME:
            return <Game/>;
    }
}

function Menu() {
    const gameState = useSelector(currentGameState);
    const gameStarted = useSelector(gameStartedSelector);
    const items = useSelector(itemsInMenuSelector);
    const revisions = useSelector(currentDocumentRevisionsSelector);
    const highlighted = useSelector(currentlyHighlightedDocumentIds);
    const dispatch = useDispatch();

    const renderMenuItem = (item: Document) => {
        const revision = item.variants.find(it => it.id === revisions[item.id]) ?? item.variants[0];
        const name = revision.file.includes('menovka') ? <Translated translationKey={KEY_NAMETAG}/> : revision.name;

        let button = <Button disabled={!gameStarted} onClick={() => dispatch(viewDocumentAction(item.id))}
                             color="inherit">{name}</Button>;

        if (highlighted.includes(item.id)) {
            button = <Badge color="secondary" variant="dot">{button}</Badge>;
        }

        return <Box key={item.id} style={{margin: '0 0.5em'}}>{button}</Box>;
    };

    return gameState === GameState.IN_GAME ? <>{items.map(renderMenuItem)}</> : (null);
}

function App() {
    return <ThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <ShortPollingSync interval={5000}/>
                    <AppBar position="static">
                        <Toolbar variant={"dense"}>
                            <Hidden xsDown>
                                <Box style={{flexGrow: 1}}>
                                    <Typography variant="h6"><AppTitle/></Typography>
                                    <Typography variant="subtitle2"><MyCharacter/></Typography>
                                </Box>
                            </Hidden>
                            <Menu/>
                        </Toolbar>
                    </AppBar>
                    <ScreenSwitch/>
                </Router>
            </PersistGate>
        </Provider>
    </ThemeProvider>;
}

export default App;
